import React from "react";
import { Link } from "react-router-dom";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import image from "../../assets/images/shield-paper.svg"

const MaintenanceModal = ({
    dateStr,
    show,
    closeModal,
}) => {

    const hideModal = () => {
        localStorage.setItem("showAvailability", "false")
        closeModal(false)
    }

    return (
        <>

            <div className={"holiday " + (!show && "hidden")} 
                style={{backgroundImage: "linear-gradient(to bottom right, #f55511, #e29954)", boxShadow: "0px 25px 25px rgba(0, 0, 0, 0.1)", 
                        borderRadius: "10px", maxWidth: "600px", width: "100%", padding: "20px", paddingTop: "15px", position: "fixed", bottom: "30px", right: "30px"}}>
                <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <div id="id_eoy_modal1" href="#" onClick={closeModal} className="cross"></div>
                </div>
                <div style={{marginBottom: "20px", color: "white", display: "flex", flexWrap: "wrap", alignItems: "center"}}>
                    <i className="fas fa-snowflake" style={{fontSize:"xx-large"}}></i>
                    <p style={{fontStyle: "normal", fontWeight: "1000", fontSize: "20px", lineHeight: 1, marginBottom: 0, marginLeft: "15px"}}> Happy Holidays!</p>
                </div>

                <div className="par-one" style={{marginBottom: "20px", position: "relative"}}>
                    <p style={{fontStyle: "normal", fontWeight: "normal", paddingBottom: "20px", fontSize: "16px", lineHeight: "26px", color: "#FFFFFF"}}>
                        We're closed over the festive season from <span style={{fontWeight: "1000 !important"}}> <b> {process.env.REACT_APP_HOLIDAY_DATE_STRING}.</b></span>
                    </p>
                </div>


                <div style={{marginBottom: "40px", display: "flex", flexWrap: "wrap", width: "100%"}}>
                    <div style={{width: "60%", display: "inline-block"}}>
                        <p style={{fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "26px", color: "#FFFFFF", verticalAlign: "middle", margin: 0}}>
                            <b>But don't worry!</b> We will contact your referral(s) as soon as we get back into the office.</p>
                    </div>


                </div>

                <a id="id_eoy_modal2" href="#" onClick={()=>hideModal(false)} style={{fontStyle: "normal", fontSize: "20px", textTransform: "uppercase", lineHeight: 1, letterSpacing: "1.5px", color: "#FFFFFF", textDecoration: "none"}}>ACKNOWLEDGE AND CLOSE</a>

            </div>


        </>
    );
};

export default MaintenanceModal;
